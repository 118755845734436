import { helper } from "./helper";
import UAParser from 'ua-parser-js';

// Configuración de reintentos
const RETRY_CONFIG = {
    maxRetries: 5,
    initialDelay: 1000, // 1 segundo
    maxDelay: 7000,     // 5 segundos
    backoffFactor: 1.5,   // Factor de incremento para backoff exponencial
};

// Función para esperar con backoff exponencial
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Función para obtener el delay del siguiente intento
const getBackoffDelay = (attempt) => {
    const delay = RETRY_CONFIG.initialDelay * Math.pow(RETRY_CONFIG.backoffFactor, attempt);
    return Math.min(delay, RETRY_CONFIG.maxDelay);
};

// Función para obtener IP con reintentos
async function getIPAddress(retries = RETRY_CONFIG.maxRetries) {
    const ipServices = [
        'https://api.ipify.org?format=json',
        'https://api.ip.sb/ip',
        'https://api.myip.com'
    ];

    for (let attempt = 0; attempt < retries; attempt++) {
        for (const service of ipServices) {
            try {
                const response = await fetch(service);
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

                const data = await response.text();
                // Manejar diferentes formatos de respuesta
                if (service.includes('ipify')) {
                    return JSON.parse(data).ip;
                } else if (service.includes('ip.sb')) {
                    return data.trim();
                } else if (service.includes('myip.com')) {
                    return JSON.parse(data).ip;
                }
            } catch (error) {
                console.warn(`Attempt ${attempt + 1}: Failed to fetch IP from ${service}:`, error);
                continue; // Intentar con el siguiente servicio
            }
        }

        if (attempt < retries - 1) {
            await sleep(getBackoffDelay(attempt));
        }
    }

    return "Unknown";
}

// Función para validar los datos de entrada
function validateInput(user, user_action, module, action_type, status) {
    const validations = [
        { condition: !user?.id, message: "User ID is required" },
        { condition: !user?.nombres, message: "User name is required" },
        { condition: !user_action, message: "User action is required" },
        { condition: !module, message: "Module is required" },
        { condition: !action_type, message: "Action type is required" },
        { condition: !status, message: "Status is required" },
        { condition: !['success', 'failed'].includes(status), message: "Status must be 'success' or 'failed'" }
    ];

    const failed = validations.find(v => v.condition);
    if (failed) {
        throw new Error(`Validation error: ${failed.message}`);
    }
}

/**
 * Logs user actions into the system with retry mechanism and enhanced error handling.
 *
 * @param {Object} user - The user performing the action. Must contain `id` and `nombres` properties.
 * @param {string} user_action - A short description of the action performed.
 * @param {string} module - The module name where the action occurred.
 * @param {string} action_type - Action type (e.g., "create", "update", "delete").
 * @param {string} status - Action status ("success" or "failed").
 * @param {string} [message=""] - Additional details or error message.
 * @param {any} [old_data=null] - Previous data state.
 * @param {any} [new_data=null] - New data state.
 * @param {any} [error=null] - Error object.
 * @returns {Promise<Object>} The logging response.
 * @throws {Error} If validation fails or if logging fails after all retries.
 */
export default async function logger(
    user,
    user_action,
    module,
    action_type,
    status,
    message = "",
    old_data = null,
    new_data = null,
    error = null
) {
    try {
        // Validar datos de entrada
        validateInput(user, user_action, module, action_type, status);

        // Obtener información del dispositivo
        const parser = new UAParser(navigator.userAgent);
        const deviceInfo = parser.getResult();

        // Obtener IP con sistema de reintentos
        const ipAddress = await getIPAddress();

        const logData = {
            userId: user.id,
            userName: user.nombres,
            userAction: user_action,
            module,
            actionType: action_type,
            status,
            ipAddress,
            deviceInfo: {
                browser: deviceInfo.browser.name || "Unknown",
                os: deviceInfo.os.name || "Unknown",
                device_type: deviceInfo.device.type || "Desktop",
                browser_version: deviceInfo.browser.version,
                os_version: deviceInfo.os.version,
            },
            errorMessage: status === "failed" ? message : "",
            details: {
                message: message? message: "",
                url: window.location.href,
                old_data: old_data ? JSON.stringify(old_data) : "",
                new_data: new_data ? JSON.stringify(new_data) : "",
                error: error ? JSON.stringify(error) : "",
            }
        };

        // Implementar sistema de reintentos para el post
        let lastError;
        for (let attempt = 0; attempt < RETRY_CONFIG.maxRetries; attempt++) {
            try {
                const response = await helper.post("log", logData);
                return response;
            } catch (error) {
                lastError = error;
                console.warn(`Attempt ${attempt + 1} failed:`, error);

                if (attempt < RETRY_CONFIG.maxRetries - 1) {
                    await sleep(getBackoffDelay(attempt));
                }
            }
        }

        // Si llegamos aquí, todos los intentos fallaron
        throw new Error(`Failed to log after ${RETRY_CONFIG.maxRetries} attempts. Last error: ${lastError.message}`);

    } catch (error) {
        // Intentar registrar el error localmente si todo lo demás falla
        try {
            const errorLog = {
                timestamp: new Date().toISOString(),
                error: error.message,
                data: { user, user_action, module, action_type, status, message }
            };
            localStorage.setItem(`failed_log_${Date.now()}`, JSON.stringify(errorLog));
        } catch (localStorageError) {
            console.error('Failed to save error log locally:', localStorageError);
        }

        // Re-lanzar el error para manejo superior
        throw error;
    }
}

// Función auxiliar para sincronizar logs fallidos
export async function syncFailedLogs() {
    const failedLogs = Object.entries(localStorage)
        .filter(([key]) => key.startsWith('failed_log_'))
        .map(([key, value]) => ({ key, ...JSON.parse(value) }));

    for (const log of failedLogs) {
        try {
            await logger(
                log.data.user,
                log.data.user_action,
                log.data.module,
                log.data.action_type,
                log.data.status,
                log.data.message
            );
            localStorage.removeItem(log.key);
        } catch (error) {
            console.error(`Failed to sync log ${log.key}:`, error);
        }
    }
}