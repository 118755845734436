import React, { useState, useContext, Suspense } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Inicio from "./components/inicio/Inicio";
import Navbar from "./components/bars/Navbar";
import Sidebar from "./components/bars/Sidebar";
import "./App.css";
import useStyles from "./Styles";
import { UsuarioContext } from "./components/context/UsuarioContext";
import Loader from "./components/loader/Loader";

const Usuario = React.lazy(() => import("./components/usuario/Usuario"));
const Expediente = React.lazy(() => import("./components/expediente/Expediente"));
const Empresa = React.lazy(() => import("./components/empresa/Empresa"));
const Auditoria = React.lazy(() => import("./components/auditoria/Auditoria"));
const Evaluacion = React.lazy(() => import("./components/evaluacion/Evaluacion"));
const Pac = React.lazy(() => import("./components/pac/Pac"));
const Log = React.lazy(() => import("./components/log/Log"));
const Calendario = React.lazy(() => import("./components/calendario/Calendario"));

export default function App({ cambiarEstado }) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    const { user } = useContext(UsuarioContext);

    const cambiarOpen = () => {
        setOpen(!open);
    };

    if (user === null) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return (
        <div className={classes.navegador}>
            <Router>
                <CssBaseline />
                <Navbar open={open} cambiarOpen={cambiarOpen} />
                <Sidebar open={open} cambiarOpen={cambiarOpen} theme={theme} cambiarEstado={cambiarEstado} user={user} />
                <div
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.drawerHeader}></div>

                    {user?.rol === "Administrador" && (
                        <Switch>
                            <Suspense fallback={<Loader />}>
                                <Route exact path="/" render={() => <Redirect to="/inicio" />} />
                                <Route exact path="/inicio" component={Inicio} />
                                <Route exact path="/usuario" component={Usuario} />
                                <Route exact path="/empresa" component={Empresa} />
                                <Route exact path="/evaluacion" component={Evaluacion} />
                                <Route exact path="/auditoria">
                                    <Auditoria open={open} />
                                </Route>
                                <Route exact path="/calendario" component={Calendario} />
                                <Route exact path="/log" component={Log} />
                            </Suspense>
                        </Switch>
                    )}

                    {user?.rol === "Auditor Lider" && (
                        <Switch>
                            <Suspense fallback={<Loader />}>
                                <Route exact path="/" render={() => <Redirect to="/inicio" />} />
                                <Route exact path="/inicio" component={Inicio} />
                                <Route exact path="/empresa" component={Empresa} />
                                <Route exact path="/evaluacion" component={Evaluacion} />
                                <Route exact path="/auditoria">
                                    <Auditoria open={open} />
                                </Route>
                                <Route exact path="/calendario" component={Calendario} />
                                <Route exact path="/pac" component={Pac} />
                            </Suspense>
                        </Switch>
                    )}

                    {user?.rol === "Auditor" && (
                        <Switch>
                            <Suspense fallback={<Loader />}>
                                <Route exact path="/" render={() => <Redirect to="/inicio" />} />
                                <Route exact path="/inicio" component={Inicio} />
                                <Route exact path="/auditoria" component={Auditoria} />
                                <Route exact path="/pac" component={Pac} />
                                <Route exact path="/calendario" component={Calendario} />
                            </Suspense>
                        </Switch>
                    )}

                    {user?.rol === "Cliente" && (
                        <Switch>
                            <Suspense fallback={<Loader />}>
                                <Route exact path="/" render={() => <Redirect to="/expediente" />} />
                                <Route exact path="/expediente" component={Expediente} />
                            </Suspense>
                        </Switch>
                    )}
                </div>
            </Router>
        </div>
    );
}
