import { useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Card, CardMedia, CardContent, Typography, Container } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { UsuarioContext } from "../context/UsuarioContext"

// Estilos personalizados
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4)
    },
    welcome: {
        marginBottom: theme.spacing(4),
        color: "#000000", // Texto negro
        fontWeight: 700,
        textAlign: "center",
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        position: "relative",
        overflow: "hidden",
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 12px 20px rgba(0,0,0,0.2)",
        },
    },
    mediaContainer: {
        position: "relative",
        paddingTop: "56.25%",
    },
    media: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        filter: "brightness(90%); blur(2px)",
        transition: "all 0.3s ease-in-out",
    },
    content: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        padding: theme.spacing(2),
        paddingBottom: "0",
        background: "linear-gradient(to top, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 100%)",
    },
    title: {
        fontSize: "1.4rem",
        fontWeight: 600,
        textAlign: "center",
        color: "#000000", // Texto blanco
    },
}))

const NavigationCard = ({ title, image, path, onClick }) => {
    const classes = useStyles()

    return (
        <Card className={classes.card} onClick={onClick}>
            <div className={classes.mediaContainer}>
                <CardMedia className={classes.media} image={image} title={title} />
                <CardContent className={classes.content}>
                    <Typography className={classes.title} variant="h6">
                        {title}
                    </Typography>
                </CardContent>
            </div>
        </Card>
    )
}

const Inicio = () => {
    const classes = useStyles()
    const history = useHistory()
    const { user } = useContext(UsuarioContext)

    const getNavigationItems = () => {
        switch (user?.rol) {
            case "Administrador":
                return [
                    { title: "USUARIOS", path: "/usuario", image: "/usuarios.png" },
                    { title: "EMPRESAS", path: "/empresa", image: "/empresas.png" },
                    { title: "EXPEDIENTES", path: "/evaluacion", image: "/expedientes.png" },
                    { title: "AUDITORIAS", path: "/auditoria", image: "/auditorias.png" },
                    { title: "CALENDARIO", path: "/calendario", image: "/calendario.png" },
                    { title: "LOGS", path: "/log", image: "/registros.png" },
                ]
            case "Auditor Lider":
                return [
                    { title: "EMPRESAS", path: "/empresa", image: "/empresas.png" },
                    { title: "EXPEDIENTES", path: "/evaluacion", image: "/expedientes.png" },
                    { title: "AUDITORIAS", path: "/auditoria", image: "/auditorias.png" },
                    { title: "CALENDARIO", path: "/calendario", image: "/calendario.png" },
                ]
            case "Auditor":
                return [
                    { title: "AUDITORIAS", path: "/auditoria", image: "/auditorias.png" },
                    { title: "CALENDARIO", path: "/calendario", image: "/calendario.png" },
                ]
            case "Cliente":
                return [{ title: "EXPEDIENTES", path: "/expediente", image: "/expedientes.png" }]
            default:
                return []
        }
    }

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Typography variant="h3" className={classes.welcome}>
                ¡Te damos la bienvenida, {user?.nombres?.split(" ")[0]}!
            </Typography>
            <Grid container spacing={4}>
                {getNavigationItems().map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <NavigationCard
                            title={item.title}
                            image={item.image}
                            path={item.path}
                            onClick={() => history.push(item.path)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default Inicio







