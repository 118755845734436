import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Logeo from "./components/login/Logeo";
import { helper } from "./components/helper/helper";
import UsuarioProvider from "./components/context/UsuarioContext";
import { UsuarioContext } from "./components/context/UsuarioContext";
import AlertProvider from "./components/context/AlertContext";
import Alerta from "./components/helper/Alerta";
import logger from "./components/helper/logger";

const VerLogueo = () => {
    //trae los hooks creado en el contexto
    const { setUser } = useContext(UsuarioContext);

    //preguntar a la api los datos del usuario a través del token
    const validarTokenUsuario = async () => {
        try {
            await helper.post("sesion/ver", "").then((res) => {
                let { status, usuario } = res.data;
                if (status === 200) {
                    setUser(usuario);

                    logger(
                        usuario,
                        "session_validation",
                        "auth",
                        "read",
                        "success",
                        "Validación de token exitosa",
                        null,
                        { userId: usuario.id },
                        null
                    );

                    return true;
                } else {
                    return false;
                }
            });
        } catch (error) {
            localStorage.removeItem("token");
            cambiarEstado();
            console.error(error);
        }
    };

    //asgina el return de la función de validartokenusuario
    const [logeo, setLogeo] = useState(() => {
        if (localStorage.getItem("token") !== null) {
            return validarTokenUsuario();
        } else {
            return false;
        }
    });

    //función para cambiar el estado, pasarlo por props
    const cambiarEstado = () => {
        setLogeo(!logeo);
    };

    //retorna componentes por el estado del logeo
    return (
        <>
            <Alerta />
            {logeo ? <App cambiarEstado={cambiarEstado} /> : <Logeo cambiarEstado={cambiarEstado} />}
        </>
    );
};

ReactDOM.render(
    <UsuarioProvider>
        <AlertProvider>
            <VerLogueo />
        </AlertProvider>
    </UsuarioProvider>,
    document.getElementById("root")
);
